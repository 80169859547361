.tree-view {
  font-family: 'Roboto', Arial, sans-serif;
  color: var(--text-color);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.search-bar {
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
}

.search-bar input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-bar input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}

.tree-content {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.tree-node {
  margin-left: 20px;
}

.node-content {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;
  transition: background-color 0.2s ease;
}

.node-content:hover {
  background-color: rgba(52, 152, 219, 0.1);
}

.icon {
  margin-right: 8px;
  font-size: 16px;
  color: var(--primary-color);
}

.node-name {
  font-size: 14px;
}

.node-children {
  margin-left: 20px;
}

.how-to-upload-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.how-to-upload-button:hover {
  background-color: #2980b9;
  transform: scale(1.05);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  max-width: 850px; /* Increase the max-width to make the modal wider */
  width: 90%; /* Adjust the width to make it responsive */
  position: relative;
}

.modal h2 {
  margin-bottom: 1rem;
  font-size: 24px;
  color: var(--secondary-color);
}

.modalContent {
  text-align: left;
}

.modalContent p {
  margin-bottom: 1rem;
  font-size: 16px;
  color: var(--text-color);
}

.modalContent ol {
  margin-bottom: 1rem;
}

.modalContent ol li {
  margin-bottom: 0.5rem;
  font-size: 14px;
}

.codeContainer {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 0.5rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  position: relative;
}

.codeContainer pre {
  margin: 0;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.clipboardIcon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.closeButton {
  background: transparent;
  border: none;
  font-size: 16px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.closeButton:hover {
  color: var(--primary-color);
}
