.file-uploader {
    border: 2px dashed var(--border-color);
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: var(--background-color);
  }
  
  .file-uploader:hover {
    border-color: var(--primary-color);
    background-color: rgba(52, 152, 219, 0.05);
  }
  
  .uploader-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .upload-icon {
    font-size: 48px;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .upload-text {
    margin: 0;
    color: var(--text-light);
    font-size: 14px;
  }