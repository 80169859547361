:root {
    --primary-color: #3498db;
    --secondary-color: #2c3e50;
    --background-color: #f5f7fa;
    --panel-background: #ffffff;
    --border-color: #e0e6ed;
    --text-color: #34495e;
    --text-light: #7f8c8d;
  }
  
  body {
    font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
    margin: 0;
    padding: 0;
  }
  
  .iam-policy-visualizer {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .header {
    background-color: var(--secondary-color);
    color: white;
    padding: 0.75rem 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }

  .header-left {
    width: 100px; /* Adjust as needed to balance with header-actions */
  }
  
  .app-title {
    font-size: 1.4rem;
    margin: 0;
    font-weight: 500;
    text-align: center;
    flex-grow: 1;
  }
  
  
  .header-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100px; /* Adjust as needed to balance with header-left */
    justify-content: flex-end;
  }
  
  .github-link {
    color: white;
    font-size: 1.5rem;
    transition: opacity 0.3s ease;
  }
  
  
  .github-link:hover {
    opacity: 0.8;
  }
  
  .buy-me-coffee-button img {
    height: 32px;
    transition: transform 0.3s ease;
  } 
 
  
  .buy-me-coffee-button:hover img {
    transform: scale(1.05);
  }
  
  .content-wrapper {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  
  .left-panel {
    display: flex;
    flex-direction: column;
    background-color: var(--panel-background);
    border-right: 1px solid var(--border-color);
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.05);
  }
  
  .right-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: var(--panel-background);
  }


  .tabs {
    display: flex;
    background-color: var(--secondary-color);
    padding: 0.5rem 0.5rem 0;
  }
  
  .tab {
    padding: 0.5rem 1rem;
    margin-right: 0.25rem;
    background-color: var(--panel-background);
    border: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }
  
  .tab:hover {
    opacity: 0.9;
  }
  
  .tab.active {
    opacity: 1;
    font-weight: bold;
  }
  
  .tab-content {
    flex: 1;
    overflow: auto;
    padding: 1rem;
  }
  
  .file-uploader {
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .tree-json-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
  
  .tree-view-container {
    overflow: auto;
    min-height: 200px;
  }
  
  .json-input-container {
    flex: 1;
    overflow: hidden;
    border-top: 1px solid var(--border-color);
    min-height: 200px;
  }
  
  .visualization {
    height: 100%;
  }
  
  .react-resizable {
    position: relative;
  }
  
  .custom-handle {
    position: absolute;
    background-color: var(--primary-color);
    opacity: 0.2;
    z-index: 1;
    transition: opacity 0.3s ease;
  }
  
  .custom-handle-e {
    width: 4px;
    height: 100%;
    right: -2px;
    top: 0;
    cursor: col-resize;
  }
  
  .custom-handle-s {
    width: 100%;
    height: 4px;
    bottom: -2px;
    left: 0;
    cursor: row-resize;
  }
  
  .custom-handle:hover {
    opacity: 0.5;
  }
  
  .footer {
    background-color: var(--secondary-color);
    color: white;
    text-align: center;
    padding: 0.5rem;
    font-size: 0.9rem;
  }
  
  .footer a {
    color: var(--primary-color);
    text-decoration: none;
  }
  
  @media (max-width: 768px) {

    .header-content {
        flex-direction: column;
        gap: 0.5rem;
      }

      .header-left {
        display: none;
      }
    

    .header {
      padding: 0.5rem 0.75rem;
    }
  
    .app-title {
        font-size: 1.2rem;
    }

    .header-actions {
        width: auto;
      }
  
    .github-link {
        font-size: 1.3rem;
    }
  
    .buy-me-coffee-button img {
      height: 28px;
    }
  
    .left-panel {
      min-width: 250px;
    }
  }